<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
             <SideMenu :indexsec="15"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
           
            <form
              @submit.prevent="
                submit(
                 name_form,
                 file1,
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR CONCURSO</p>
              </div>


              <div class="row mx-0 row-one">
                <div class="EPF1_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="name_form"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>

                <div class="div-image QUITLEFT">
                  <label class="labelImg color-1" for="inputImg">Logo</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                    required
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>

              


           
               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El Concurso se guardo correctamente</p>
                  </div>
                  <div class="">
                   
                    <div class="btns-dflx">
                      <div class="btn-void"></div>
                      <div class="btn-void-2"></div>
                      <b-button class="btn-modal" @click="toEdit()"
                        >EDITAR</b-button
                      >
                      <div class="btn-void"></div>
                    </div>


                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
        
            
              
            
            
           


  
          
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import Header from '../../components/Header';


export default {
  name: "AddLanding",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
   
      
      name_form:"",
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      added:''

    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    
    
  },
  computed:{
     
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
        url() {
      return this.$store.getters["main/baseURL"];
    }
  },
  methods: {
   
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    toEdit: function () {
       this.$router.push("/editar/landing/"+this.added.id).catch((err) => {});
    },
    submit: async function (  name, image) {
          this.status=''
          this.msg=''

   

         if(image == null){
            this.status='error'
            this.msg='Imagen no subida'
        }else{
          if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{

                var data = new  FormData();
              
                  data.append('image', image);
                  data.append('name',name);
                  data.append('_method', 'POST');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/addItem",  {option:'landing', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                  }else{ // success
                      this.content=''
                      this.added = result.added
                      this.showModal() 
                  }
                
            }
        }

    },
    agregarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
  

  },
};
</script>
<style scoped>
  .btn-void-2{
    width: 5vw;
  }
</style>
